import React from 'react';
import Temperature from '../CurrentWeather/Temperature';
import WeatherIcon from '../CurrentWeather/WeatherIcon';
import { ForecastItemContainer } from './styled';

interface IForecastItemProps {
  day: string;
  weatherCode: number;
  high: number;
  low: number;
  main: string;
}

function translateMain(main: string): string {
  switch (main) {
    case 'Clouds': return 'Pochmurno';
    case 'Snow': return 'Śnieg';
    case 'Rain': return 'Deszcz';
    case 'Clear': return 'Bezchmurnie';
    default: return main;
  }
}

const ForecastItem: React.FC<IForecastItemProps> = (props) => {
  const translatedMain = translateMain(props.main);

  return (
    <ForecastItemContainer>
      <h6>{props.day}</h6>
      <WeatherIcon code={props.weatherCode} />
      <p>{translatedMain}</p>
      <span>
        <Temperature value={props.high} />
        <sup>&deg;</sup>
        <small>/</small>
        <Temperature value={props.low} />
        <sup>&deg;</sup>
      </span>
    </ForecastItemContainer>
  );
};

export default ForecastItem;
