import styled from 'styled-components';

export const FooterContainer = styled.footer`
  p {
    padding: 1rem 0;
    font-size: 1rem;
    text-align: center;
    color: #2f6f8a;
    a {
      color: #1a5a73;
    }
  }
`;
