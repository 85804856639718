import React from 'react';
import { FooterContainer } from './styled';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <p>
        Created By <a href="https://b4x.pl">b4x.pl</a> | Sprawdź <a href="/wiadomosci/">wiadomości pogodowe</a> | <a href="https://radarburz.net" target="_blank">RadarBurz.net</a> 
      </p>
    </FooterContainer>
  );
};

export default Footer;
