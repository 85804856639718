export const fetchCities = async (search: string) => {
  const url = `https://api.datachecker.pl/autosuggestion/cities?q=${encodeURIComponent(search)}`;
  const res = await (
    await fetch(url, {
      method: 'GET',
    })
  ).json();

  return res.data
    .map((i: any) => {
      return i.city + ', ' + i.countryCode;
    });
};
