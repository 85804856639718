import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../components/Footer/Footer';
import Forecast from '../components/Forecast/Forecast';
import Header from '../components/Header/Header';
import Search from '../components/Search/Search';
import Spinner from '../components/ui/Spinner/Spinner';
import CurrentWeather from '../components/CurrentWeather/CurrentWeather';
import Sidebar from '../components/Sidebar/Sidebar'; // Import your Sidebar component
import styled from 'styled-components';
import { AppStore } from '../store/store';


const HomeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainContent = styled.div`
  flex: 1;
  max-width: 100vw;
`;


const RightContent = styled.div`
  flex: 1;
  max-width: 10vw;
`;

const Home = () => {
  const { loading } = useSelector((state: AppStore) => ({
    loading: state.app.isLoading,
  }));

  return (
    <>
      <HomeContainer>
        {loading && <Spinner />}
        <MainContent>
          <Header />
          <Search />
          <CurrentWeather />
          <Forecast />
          <Footer />
        </MainContent>
        {/* <RightContent>
        <Sidebar />
        </RightContent> */}

      </HomeContainer>
    </>
  );
};

export default Home;
